import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["CelebratingSkeeAnimation"] */ "/__w/site-skeelo/site-skeelo/src/components/new/animations/celebratingSkee/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CellphoneSkeeAnimation"] */ "/__w/site-skeelo/site-skeelo/src/components/new/animations/cellphoneSkee/index.tsx");
;
import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/src/components/new/animations/sparklesOne/index.tsx");
;
import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/src/components/new/animations/sparklesTwo/index.tsx");
;
import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/src/components/new/checkBenefitButton/index.tsx");
;
import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/src/components/new/illustrationCard/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/src/components/new/openAppButton/index.tsx");
;
import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/src/sections/bigNumbers/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BooksCarousel"] */ "/__w/site-skeelo/site-skeelo/src/sections/booksCarousel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FormatsInfo"] */ "/__w/site-skeelo/site-skeelo/src/sections/formatsInfo/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FormatsHomeSectionButton"] */ "/__w/site-skeelo/site-skeelo/src/sections/home/formats/button/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroCarouselsSection"] */ "/__w/site-skeelo/site-skeelo/src/sections/home/hero/carousels/index.tsx");
;
import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/src/sections/home/hero/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/src/sections/home/info/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SecretCodeHomeSection"] */ "/__w/site-skeelo/site-skeelo/src/sections/home/secretCode/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SkoobHomeSectionButton"] */ "/__w/site-skeelo/site-skeelo/src/sections/home/skoob/button/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PublishersCarousel"] */ "/__w/site-skeelo/site-skeelo/src/sections/publishersCarousel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReleasesCarousel"] */ "/__w/site-skeelo/site-skeelo/src/sections/releasesCarousel/index.tsx");
